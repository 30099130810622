
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';

    import LoginFormCard from '@/components/LoginFormCard.vue';
    import {IAccount} from '@/types';

    const accountNamespace = namespace('account');

    @Component<ViewLogin>({
        components: {
            LoginFormCard,
        },
    })
    export default class ViewLogin extends Vue {
        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount|null;

        public onLogged() {
            if (this.loggedAccount && this.loggedAccount.status === 'pending_details') {
                this.$router.push({name: 'register'});
            } else {
                this.$router.push('/');
            }
        }

        public goToRegister() {
            this.$router.push({name: 'register'});
        }
    }
