
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Prop} from 'vue-property-decorator';

    import {AccountModule, SnackbarModule} from '@/store/modules';

    const accountNamespace = namespace('account');

    @Component<LoginFormCard>({})
    export default class LoginFormCard extends Vue {
        public error: boolean = false;
        public loginFormValid: boolean = false;
        public username: string|null = null;
        public password: string|null = null;
        public showpassword: boolean = false;

        public usernameRules = [
            (v: string) => !!v || 'L\'adresse e-mail est requise',
            (v: string) => /.+@.+\..+/.test(v) || 'Le format de l\'adresse e-mail est incorrect',
        ];

        public passwordRules = [
            (v: string) => !!v || 'Le mot de passe est requis',
        ];

        @Prop({
            type: Boolean,
            required: false,
            default: true,
        })
        public showSuccessMessage!: boolean;

        @Prop({
            type: String,
            required: false,
        })
        public initialUsername!: string;

        @accountNamespace.Getter('loginStatus')
        public authStatus!: string|null;

        public submitLogin() {
            this.error = false;
            const snackbarModule = getModule(SnackbarModule, this.$store);

            if (this.username && this.password) {
                getModule(AccountModule, this.$store)
                    .login({username: this.username, password: this.password})
                    .then(() => {
                        snackbarModule.displaySuccess('Bienvenue sur Trouveunveto !');
                        this.$emit('logged');
                    })
                    .catch((error) => {
                        if (error.response.status === 403) {
                            snackbarModule.displayError('Ces identifiants correspondent à un compte client. Pour vous connecter, <a href="https://vetolib.fr/connexion">cliquez ici</a>');
                        } else {
                            snackbarModule.displayError('Adresse email ou mot de passe invalide');
                            this.error = true;
                        }
                    })
                ;
            }
        }

        public mounted() {
            if (this.$route.query.email) {
                this.username = this.$route.query.email as string;
            } else if (this.initialUsername) {
                this.username = this.initialUsername;
            }
        }
    }
